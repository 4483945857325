import React from 'react';
import PropTypes from 'prop-types';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/newsletter/newsletterMainContent';

/**
 * Landing page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Newsletter location={string} />
 */

const Newsletter = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout location={location}>
      <Main location={location} />
    </Layout>
  );
};

Newsletter.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Newsletter;
