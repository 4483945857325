import React from 'react';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './newsletterForm';

/**
 * Landing page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => (
  <section
    className={cx(
      base.flex,
      base.itemsCenter,
      base.justifyCenter,
      base.w100,
      main.contactMain
    )}
  >
    <div
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactInner
      )}
    >
      <Form />
    </div>
  </section>
);

export default Main;
